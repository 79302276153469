import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="search"
export default class extends Controller {
  static targets = [
    'input',
    'submitter',
  ]

  connect() {
    this.inputTarget.addEventListener('keydown', (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        this.inputTarget.form.requestSubmit(this.submitterTarget);
      }
    })
  }
}
