import { Controller } from "@hotwired/stimulus"

export default class EllipsisTitleController extends Controller {
  connect() {
    const ellipsisActive = this.element.offsetWidth < this.element.scrollWidth;
    if (ellipsisActive) {
      this.element.title = this.element.innerText;
    }
  }
}
