import { Controller } from "@hotwired/stimulus";

const isTestEnvironment = 'CapybaraLockstep' in window;

export default class TinymceController extends Controller {
  static targets = [
    "editor",
    "content"
  ]

  tinymce = null;

  connect() {
    this.element.classList.add('rich-text-editor')
  }

  async editorTargetConnected(element) {
    this.tinymce = (await tinymce.init({
      target: element,
      language: isTestEnvironment ? undefined : 'nb_NO',
      branding: false,
      promotion: false,
      statusbar: false,
      plugins: [
        'lists',
        'link',
        'table',
        'advlist',
        'autoresize',
        'autolink',
        'wordcount'
      ],
      toolbar: 'undo redo | bold italic underline | numlist bullist | table | link',
      content_css: ['default', RAILS_ASSET_URL('/rich-text-content.scss')],
    }))[0]
  }

  contentTargetConnected(element) {
    this.getForm().addEventListener('submit', (event) => {
      element.value = this.tinymce?.getContent()
    })
  }

  getForm() {
    return this.element;
  }
}
