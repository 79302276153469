import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="category-navigation"
export default class extends Controller {
  expandedCategories = {};

  connect() {
    // Load the expanded categories from localStorage
    const expandedCategories = JSON.parse(localStorage.getItem("expandedCategories") ?? "{}");
    this.expandedCategories = expandedCategories;

    // Save the default expanded categories to localStorage
    this.element.querySelectorAll("input[type=checkbox]").forEach(checkbox => {
      if (checkbox.checked) {
        const categoryId = checkbox.dataset.category;
        this.setCategoryExpanded(categoryId, true);
      }
    });

    // Restore state for any other checkboxes
    for (const [categoryId, checked] of Object.entries(this.expandedCategories)) {
      if (!checked) continue;
      const checkbox = this.findCheckbox(categoryId);
      if (checkbox) checkbox.checked = checked;
    }

    // Expand categories when opened
    this.element.querySelectorAll("button[type=submit]").forEach(button => {
      button.addEventListener('click', () => {
        const categoryId = button.getAttribute("value");
        const checkbox = this.findCheckbox(categoryId);
        if(checkbox) checkbox.checked = true;
      })
    });

    // Save the state of the checkboxes when changed
    this.element.querySelectorAll("input[type=checkbox]").forEach(checkbox => {
      checkbox.addEventListener('change', () => {
        const categoryId = checkbox.dataset.category;
        this.setCategoryExpanded(categoryId, checkbox.checked);
      })
    });
  }

  findCheckbox(categoryId) {
    return this.element.querySelector(`input[data-category="${categoryId}"]`);
  }

  setCategoryExpanded(categoryId, checked) {
    this.expandedCategories[categoryId] = checked;
    localStorage.setItem("expandedCategories", JSON.stringify(this.expandedCategories));
  }
}
