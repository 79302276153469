// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"
import * as bootstrap from "bootstrap"

document.addEventListener("turbo:load", () => {
  document.querySelectorAll('.modal').forEach((modal) => {
    modal.addEventListener('show.bs.modal', (event) => {
      if (event.relatedTarget) {
        event.relatedTarget.blur()
      }
    })

    if (modal.tagName === 'TURBO-FRAME') {
      modal.addEventListener('turbo:before-fetch-response', (event) => {
        if (event.target.tagName === 'FORM' && event.target.dataset.stickyModal) {
          let bsModal = bootstrap.Modal.getInstance(modal)
          bsModal.hide()
        }
      });
    }
  })
  // Workaround as display: none is set to not confuse RackTest
  document.getElementById("turbo-confirm").style.display = '';

  // Remove reconfirm inputs
  document.querySelectorAll('.js-input-reconfirm').forEach((element) => element.remove())
})

Turbo.config.forms.confirm = (message, element) => {
  let dialog = document.getElementById("turbo-confirm")
  dialog.querySelector(".modal-body").textContent = message
  let bsModal = new bootstrap.Modal(dialog)
  bsModal.show()

  return new Promise((resolve) => {
    dialog.addEventListener("hide.bs.modal", () => {
      resolve(false)
    }, { once: true })
    dialog.querySelector(".btn-primary").addEventListener("click", (event) => {
      resolve(true)
      bsModal.hide()
    }, { once: true })
  })
};

if ('CapybaraLockstep' in window) {
  let isBusy = false;
  const observer = new MutationObserver((mutations) => {
    mutations.forEach((mutation) => {
      let newBusy = mutation.target.getAttribute('aria-busy') === 'true';
      if (newBusy === isBusy) return;
      if (newBusy) {
        window.CapybaraLockstep.startWork('aria-busy')
      } else  {
        window.CapybaraLockstep.stopWork('aria-busy')
      }
      isBusy = newBusy;
    })
  })
  observer.observe(document.documentElement, { attributes: true, attributeFilter: ['aria-busy'] })
}
